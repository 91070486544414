import React, { useState } from 'react';
import { userLogin } from '../../utils/httpServices/user';
import globalController from '../../mobx/GlobalController';
import { useNavigate } from 'react-router-dom';

interface FormState {
  inn: string;
  code: string;
}

interface InputProps {
  label: string;
  name: keyof FormState;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  placeholder?: string;
  autoComplete?: string;
}

const InputField = ({
  label,
  name,
  value,
  onChange,
  type = 'text',
  placeholder,
  autoComplete,
}: InputProps) => (
  <div>
    <label className="flex flex-col font-medium text-sm">
      {label}
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className="mt-2 mb-6 px-[23px] py-3 rounded-[7px] bg-[#F6F7F8]"
        placeholder={placeholder}
        autoComplete={autoComplete}
      />
    </label>
  </div>
);

const LoginForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormState>({ inn: '', code: '' });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
    let fd = new FormData();
    fd.append('inn', formData.inn);
    fd.append('code', formData.code);
    const res = await userLogin(fd);
    console.log(res);
    if (res.error) {
      globalController.handleError(res);
      return;
    }
    if (res?.token) {
      globalController.setToken(res.token);
      localStorage.setItem('token', res.token);
    }
    if (res?.user) {
      globalController.setUser(res.user);
      localStorage.setItem('user', JSON.stringify(res.user));
    }
    if (res?.register === 0 || res?.register === 1) {
      if (res.register === 1) {
        navigate('/profile', { replace: true });
      } else {
        navigate('/analyze');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputField
        label="Ваш ИНН"
        name="inn"
        value={formData.inn}
        onChange={handleInputChange}
        placeholder="Введите ваш ИНН*"
        autoComplete="login-inn"
      />
      <InputField
        label="Pin-код"
        name="code"
        value={formData.code}
        onChange={handleInputChange}
        type="password"
        placeholder="Введите ваш pin-код"
        autoComplete="login-pin"
      />
      <button
        type="submit"
        className="rounded-[7px] w-full h-[55px] text-white mt-[43px] bg-[#0A42D3]"
      >
        Войти
      </button>
    </form>
  );
};

export default LoginForm;
