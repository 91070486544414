export const APP_VERSION = '1.00';

export const URL_API = 'https://ndadmin.itcom8.pro/api/v1';

export const optionsItemsPerPage = [10, 25, 50, 100, 200];

export const slideInterval = 3000;
export const cardsPerScreen = 3;
export const cardsPerScreenSmall = 1;

export const PAGES_WITHOUT_TOKEN = ['/login', '/news'];
