import React from 'react';
import ProfileForm from './Form';

export default function Profile() {
  return (
    <div className="flex flex-col items-center min-h-screen w-full bg-[#F6F7F8]">
      <div className="my-auto bg-white rounded-[20px] p-8 pb-[57px] font-medium lg:w-[466px] w-[90%] lg:mt-[105px] mt-[30px] lg:mb-[97px] mb-[30px]">
        <img src="/images/logo_.png" alt="" className="mx-auto" />
        <h2 className="text-[25px] mt-6 mb-4 text-center">Давайте знакомиться</h2>
        <p className="text-[18px] text-center mb-[42px]">Расскажите немного о себе</p>
        <ProfileForm />
      </div>
    </div>
  );
}
