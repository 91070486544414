import React from 'react';

import Login from './modules/Login';
import Page404 from './modules/Page404/Page404';
import Profile from './modules/Profile';
import Analyze from './modules/Analyze';
import Offers from './modules/Offers';
import NewsPage from './modules/News';

const routes = [
  { path: '/', element: <Login /> },
  { path: '/login', element: <Login /> },
  { path: '/profile', element: <Profile /> },
  { path: '/analyze', element: <Analyze /> },
  { path: '/offers', element: <Offers /> },
  { path: '/news', element: <NewsPage /> },
  { path: '*', element: <Page404 /> },
];

export default routes;
