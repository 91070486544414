import axios from 'axios';

import { URL_API } from '../consts';
import globalController from '../../mobx/GlobalController';

const createAxiosInstance = () => {
  // const apiLogin = process.env.REACT_APP_API_LOGIN;
  // const apiPassword = process.env.REACT_APP_API_PASSWORD;

  // if (!apiLogin || !apiPassword) {
  //   toast('Ошибка авторизации', { type: 'error' });
  // }

  const axiosInstance = axios.create({
    baseURL: URL_API,
    // auth: {
    //   username: apiLogin || '',
    //   password: apiPassword || '',
    // },
    // headers: {
    //   UserToken: localStorage.getItem('UserToken'),
    // },
  });

  return axiosInstance;
};

const axiosInstance = createAxiosInstance();

axiosInstance.interceptors.request.use(
  (config) => {
    globalController.startRequest();
    // const isAuthenticated = Boolean(globalController.getUser());
    // if (
    //   !isAuthenticated &&
    //   !config.url.includes('/login') &&
    //   !config.url.includes('/check/token')
    // ) {
    //   window.location.replace('/login');
    // }
    return config;
  },
  (error) => {
    globalController.stopRequest();
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    globalController.stopRequest();
    return response;
  },
  (error) => {
    globalController.stopRequest();
    return Promise.reject(error);
  },
);

export default axiosInstance;
