import React from 'react';

export default function Checkbox({
  label,
  checked,
  onChange,
}: {
  label: React.ReactNode;
  checked: boolean;
  onChange: (val: boolean) => void;
}) {
  return (
    <div className="flex items-center gap-3 select-none">
      <div
        tabIndex={0}
        role="button"
        className="relative h-[18px] w-[18px] rounded-[3px] border border-[#C5C5C5] bg-[#F6F7F8] cursor-pointer"
        onClick={() => onChange(!checked)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') onChange(!checked);
        }}
      >
        {checked && (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[#000]">
            ✔
          </div>
        )}
      </div>
      {label && label}
    </div>
  );
}
