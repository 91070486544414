import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {}

export const SystemIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_74_2)">
      <path
        d="M13.0156 0.9375H0.984375C0.682336 0.9375 0.4375 1.18234 0.4375 1.48438V5.3125H7.75575L8.69835 3.42731C8.79099 3.24203 8.98034 3.125 9.1875 3.125H13.5625V1.48438C13.5625 1.18234 13.3177 0.9375 13.0156 0.9375ZM3.71875 3.67188H3.17188C2.86984 3.67188 2.625 3.42704 2.625 3.125C2.625 2.82296 2.86984 2.57812 3.17188 2.57812H3.71875C4.02079 2.57812 4.26562 2.82296 4.26562 3.125C4.26562 3.42704 4.02079 3.67188 3.71875 3.67188ZM6.45312 3.67188H5.90625C5.60421 3.67188 5.35938 3.42704 5.35938 3.125C5.35938 2.82296 5.60421 2.57812 5.90625 2.57812H6.45312C6.75516 2.57812 7 2.82296 7 3.125C7 3.42704 6.75516 3.67188 6.45312 3.67188Z"
        fill="white"
      />
      <path
        d="M8.5829 6.10394C8.49026 6.28922 8.30091 6.40625 8.09375 6.40625H0.4375V13.5156C0.4375 13.8177 0.682336 14.0625 0.984375 14.0625H13.0156C13.3177 14.0625 13.5625 13.8177 13.5625 13.5156V4.21875H9.5255L8.5829 6.10394ZM4.26562 11.875H3.71875C3.41671 11.875 3.17188 11.6302 3.17188 11.3281C3.17188 11.0261 3.41671 10.7812 3.71875 10.7812H4.26562C4.56766 10.7812 4.8125 11.0261 4.8125 11.3281C4.8125 11.6302 4.56766 11.875 4.26562 11.875ZM4.26562 9.6875H3.71875C3.41671 9.6875 3.17188 9.44266 3.17188 9.14062C3.17188 8.83859 3.41671 8.59375 3.71875 8.59375H4.26562C4.56766 8.59375 4.8125 8.83859 4.8125 9.14062C4.8125 9.44266 4.56766 9.6875 4.26562 9.6875ZM10.2812 11.875H6.45312C6.15109 11.875 5.90625 11.6302 5.90625 11.3281C5.90625 11.0261 6.15109 10.7812 6.45312 10.7812H10.2812C10.5833 10.7812 10.8281 11.0261 10.8281 11.3281C10.8281 11.6302 10.5833 11.875 10.2812 11.875ZM10.8281 9.14062C10.8281 9.44266 10.5833 9.6875 10.2812 9.6875H6.45312C6.15109 9.6875 5.90625 9.44266 5.90625 9.14062C5.90625 8.83859 6.15109 8.59375 6.45312 8.59375H10.2812C10.5833 8.59375 10.8281 8.83859 10.8281 9.14062Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_74_2">
        <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
