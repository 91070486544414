interface Props extends React.SVGProps<SVGSVGElement> {}

export const CloseIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    {...props}
  >
    <path
      d="M9.8727 9.8727C9.97139 9.77375 10.0886 9.69524 10.2177 9.64168C10.3468 9.58812 10.4852 9.56055 10.6249 9.56055C10.7647 9.56055 10.9031 9.58812 11.0322 9.64168C11.1613 9.69524 11.2785 9.77375 11.3772 9.8727L16.9999 15.4976L22.6227 9.8727C22.7215 9.77391 22.8388 9.69555 22.9678 9.64208C23.0969 9.58862 23.2352 9.5611 23.3749 9.5611C23.5147 9.5611 23.653 9.58862 23.7821 9.64208C23.9111 9.69555 24.0284 9.77391 24.1272 9.8727C24.226 9.97148 24.3043 10.0888 24.3578 10.2178C24.4113 10.3469 24.4388 10.4852 24.4388 10.6249C24.4388 10.7647 24.4113 10.903 24.3578 11.0321C24.3043 11.1611 24.226 11.2784 24.1272 11.3772L18.5023 16.9999L24.1272 22.6227C24.226 22.7215 24.3043 22.8388 24.3578 22.9678C24.4113 23.0969 24.4388 23.2352 24.4388 23.3749C24.4388 23.5147 24.4113 23.653 24.3578 23.7821C24.3043 23.9111 24.226 24.0284 24.1272 24.1272C24.0284 24.226 23.9111 24.3043 23.7821 24.3578C23.653 24.4113 23.5147 24.4388 23.3749 24.4388C23.2352 24.4388 23.0969 24.4113 22.9678 24.3578C22.8388 24.3043 22.7215 24.226 22.6227 24.1272L16.9999 18.5023L11.3772 24.1272C11.2784 24.226 11.1611 24.3043 11.0321 24.3578C10.903 24.4113 10.7647 24.4388 10.6249 24.4388C10.4852 24.4388 10.3469 24.4113 10.2178 24.3578C10.0888 24.3043 9.97148 24.226 9.8727 24.1272C9.77391 24.0284 9.69555 23.9111 9.64208 23.7821C9.58862 23.653 9.5611 23.5147 9.5611 23.3749C9.5611 23.2352 9.58862 23.0969 9.64208 22.9678C9.69555 22.8388 9.77391 22.7215 9.8727 22.6227L15.4976 16.9999L9.8727 11.3772C9.77375 11.2785 9.69524 11.1613 9.64168 11.0322C9.58812 10.9031 9.56055 10.7647 9.56055 10.6249C9.56055 10.4852 9.58812 10.3468 9.64168 10.2177C9.69524 10.0886 9.77375 9.97139 9.8727 9.8727Z"
      fill="#A3A3A3"
    />
  </svg>
);
