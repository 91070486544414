interface Props extends React.SVGProps<SVGSVGElement> {}

export const ArrowRightBtnIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_11_93)">
      <mask
        id="mask0_11_93"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="29"
        height="29"
      >
        <path d="M0 1.90735e-06H29V29H0V1.90735e-06Z" fill="white" />
      </mask>
      <g mask="url(#mask0_11_93)">
        <path
          d="M20.0508 14.5H8.91144M20.0508 14.5L15.595 18.9557M20.0508 14.5L15.595 10.0443M25.4022 3.12356L25.8764 3.59781C27.1511 4.8724 27.8672 6.60124 27.8672 8.40388V20.5961C27.8672 22.3988 27.1511 24.1276 25.8764 25.4022L25.4022 25.8764C24.1276 27.1511 22.3988 27.8672 20.5961 27.8672H8.40388C6.60124 27.8672 4.8724 27.1511 3.59781 25.8764L3.12356 25.4022C1.84892 24.1276 1.13281 22.3988 1.13281 20.5961V8.40388C1.13281 6.60124 1.84892 4.8724 3.12356 3.59781L3.59781 3.12356C4.8724 1.84892 6.60124 1.13281 8.40388 1.13281H20.5961C22.3988 1.13281 24.1276 1.84892 25.4022 3.12356Z"
          stroke="#DC3A31"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11_93">
        <rect width="29" height="29" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
