import clsx from 'clsx';
import React from 'react';
import { BankIcon } from '../Icons/Bank';
import { SystemIcon } from '../Icons/System';

export const Tag = ({
  type,
  className,
  text,
}: {
  type?: 'bank' | 'system';
  className?: string;
  text?: string;
}) => (
  <div
    className={clsx(
      'rounded-[20px] flex items-center gap-[6px] px-[10px] py-[3px] text-base font-medium w-fit h-fit text-white',
      type === 'bank' && 'bg-[#F11F22]',
      (type === 'system' || !type) && 'bg-[#0A42D3]',
      className,
    )}
  >
    {type === 'bank' && <BankIcon />}
    {type === 'system' && <SystemIcon />}
    {type === 'bank' ? 'Банк' : type === 'system' ? 'Система' : ''}
    {text && text}
  </div>
);
