import React, { useEffect, useState } from 'react';
import { FormatNumber } from '../../utils/helpers';
import Select4 from '../../components/Select4';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../components/Spinner';
import { Card } from './Card';
import { ReturningVisitorIcon } from '../../components/Icons/ReturningVisitor';
import { AudienceIcon } from '../../components/Icons/Audience';
import Header from '../../components/Header/Header';
import { Grid } from 'antd';
import { mockData } from './mockData';
import { cardsPerScreen, cardsPerScreenSmall, slideInterval } from '../../utils/consts';

const { useBreakpoint } = Grid;

const Cards = ({ showCardsNum }: { showCardsNum: number }) => (
  <>
    <Card showCardsNum={showCardsNum}>
      <p className="text-[44px] font-medium leading-none mb-1 ">{FormatNumber(34000)}</p>
      <div className="text-[18px] mb-auto">Обращений в системе</div>
      <ReturningVisitorIcon className="ml-auto" />
    </Card>
    <Card showCardsNum={showCardsNum}>
      <div className="flex text-sm font-medium mb-2">Новости • {mockData.date}</div>
      <h3 className="font-medium text-[20px]">{mockData.title}</h3>
      <p className="text-base line-clamp-3 mt-[10px]">{mockData.text}</p>
    </Card>
    <Card showCardsNum={showCardsNum}>
      <p className="text-[44px] font-medium leading-none mb-1">24.12.2034</p>
      <div className="text-[18px] mb-auto">Обращений в системе</div>
      <AudienceIcon className="ml-auto" />
    </Card>
    <Card showCardsNum={showCardsNum}>
      <p className="text-[44px] font-medium leading-none mb-1">{FormatNumber(34000)}</p>
      <div className="text-[18px] mb-auto">Обращений в системе</div>
      <ReturningVisitorIcon className="ml-auto" />
    </Card>
    <Card showCardsNum={showCardsNum}>
      <div className="flex text-sm font-medium mb-2">Новости • {mockData.date}</div>
      <h3 className="font-medium text-[20px]">{mockData.title}</h3>
      <p className="text-base line-clamp-3 mt-[10px]">{mockData.text}</p>
    </Card>
    <Card showCardsNum={showCardsNum}>
      <p className="text-[44px] font-medium leading-none mb-1">24.12.2034</p>
      <div className="text-[18px] mb-auto">Обращений в системе</div>
      <AudienceIcon className="ml-auto" />
    </Card>
    <Card showCardsNum={showCardsNum}>
      <p className="text-[44px] font-medium leading-none mb-1">{FormatNumber(34000)}</p>
      <div className="text-[18px] mb-auto">Обращений в системе</div>
      <ReturningVisitorIcon className="ml-auto" />
    </Card>
    <Card showCardsNum={showCardsNum}>
      <div className="flex text-sm font-medium mb-2">Новости • {mockData.date}</div>

      <h3 className="font-medium text-[20px]">{mockData.title}</h3>
      <p className="text-base line-clamp-3 mt-[10px]">{mockData.text}</p>
    </Card>
    <Card showCardsNum={showCardsNum}>
      <p className="text-[44px] font-medium leading-none mb-1">24.12.2034</p>
      <div className="text-[18px] mb-auto">Обращений в системе</div>
      <AudienceIcon className="ml-auto" />
    </Card>
    <Card showCardsNum={showCardsNum}>
      <p className="text-[44px] font-medium leading-none mb-1">{FormatNumber(34000)}</p>
      <div className="text-[18px] mb-auto">Обращений в системе</div>
      <ReturningVisitorIcon className="ml-auto" />
    </Card>
    <Card showCardsNum={showCardsNum}>
      <div className="flex text-sm font-medium mb-2">Новости • {mockData.date}</div>
      <h3 className="font-medium text-[20px]">{mockData.title}</h3>
      <p className="text-base line-clamp-3 mt-[10px]">{mockData.text}</p>
    </Card>
    <Card showCardsNum={showCardsNum}>
      <p className="text-[44px] font-medium leading-none mb-1">24.12.2034</p>
      <div className="text-[18px] mb-auto">Обращений в системе</div>
      <AudienceIcon className="ml-auto" />
    </Card>
  </>
);

export default function Analyze() {
  const navigate = useNavigate();
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [cards, setCards] = useState<any[]>([]);

  const screens = useBreakpoint();
  const isLargeScreen = screens.lg;
  const isSmallScreen = !isLargeScreen;
  const showCardsNum = isSmallScreen ? cardsPerScreenSmall : cardsPerScreen;

  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * 3000) + 2000;

    setTimeout(() => {
      navigate('/offers');
    }, randomNumber);

    const elms = [...document.querySelectorAll('.acard')];
    setCards(elms);
  }, []);

  useEffect(() => {
    if (cards?.length) {
      const interval = setInterval(() => {
        setSelectedIdx((prevIdx) => (prevIdx + showCardsNum) % cards.length);
      }, slideInterval);

      return () => clearInterval(interval);
    }
  }, [cards]);

  useEffect(() => {
    cards?.[selectedIdx]?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
  }, [selectedIdx]);

  return (
    <div
      className="flex flex-col items-center min-h-screen w-full"
      style={{ background: 'url(/images/bg.webp) no-repeat center', backgroundSize: 'cover' }}
    >
      <div className="lg:hidden flex w-full">
        <Header />
      </div>
      <div className="min-h-[80vh] flex flex-col items-center justify-center">
        <h2 className="font-bold text-[44px] text-white lg:mt-[90px] mt-16 lg:mb-[61px] mb-8">
          Анализируем
        </h2>

        {/* <RefreshIcon className="animate-spin" /> */}
        <Spinner className="animate-spin" />

        {/* <div className="flex lg:w-3/4 w-full gap-5 mt-[15vh] mb-[9vh] overflow-hidden">
        <Cards showCardsNum={showCardsNum} />
      </div>

      <Select4
        index={selectedIdx / showCardsNum}
        setSelectedIdx={setSelectedIdx}
        quantity={cards.length / showCardsNum}
        showCardsNum={showCardsNum}
      /> */}
      </div>
    </div>
  );
}
