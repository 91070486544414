import React from 'react';

export const CrossIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    {...props}
  >
    <path
      d="M9.8727 9.87276C9.97139 9.77381 10.0886 9.69531 10.2177 9.64174C10.3468 9.58818 10.4852 9.56061 10.6249 9.56061C10.7647 9.56061 10.9031 9.58818 11.0322 9.64174C11.1613 9.69531 11.2785 9.77381 11.3772 9.87276L16.9999 15.4976L22.6227 9.87276C22.7215 9.77397 22.8388 9.69561 22.9678 9.64214C23.0969 9.58868 23.2352 9.56116 23.3749 9.56116C23.5147 9.56116 23.653 9.58868 23.7821 9.64214C23.9111 9.69561 24.0284 9.77397 24.1272 9.87276C24.226 9.97154 24.3043 10.0888 24.3578 10.2179C24.4113 10.347 24.4388 10.4853 24.4388 10.625C24.4388 10.7647 24.4113 10.9031 24.3578 11.0321C24.3043 11.1612 24.226 11.2785 24.1272 11.3773L18.5023 17L24.1272 22.6228C24.226 22.7215 24.3043 22.8388 24.3578 22.9679C24.4113 23.097 24.4388 23.2353 24.4388 23.375C24.4388 23.5147 24.4113 23.6531 24.3578 23.7821C24.3043 23.9112 24.226 24.0285 24.1272 24.1273C24.0284 24.226 23.9111 24.3044 23.7821 24.3579C23.653 24.4113 23.5147 24.4388 23.3749 24.4388C23.2352 24.4388 23.0969 24.4113 22.9678 24.3579C22.8388 24.3044 22.7215 24.226 22.6227 24.1273L16.9999 18.5024L11.3772 24.1273C11.2784 24.226 11.1611 24.3044 11.0321 24.3579C10.903 24.4113 10.7647 24.4388 10.6249 24.4388C10.4852 24.4388 10.3469 24.4113 10.2178 24.3579C10.0888 24.3044 9.97148 24.226 9.8727 24.1273C9.77391 24.0285 9.69555 23.9112 9.64208 23.7821C9.58862 23.6531 9.5611 23.5147 9.5611 23.375C9.5611 23.2353 9.58862 23.097 9.64208 22.9679C9.69555 22.8388 9.77391 22.7215 9.8727 22.6228L15.4976 17L9.8727 11.3773C9.77375 11.2786 9.69524 11.1613 9.64168 11.0322C9.58812 10.9031 9.56055 10.7648 9.56055 10.625C9.56055 10.4853 9.58812 10.3469 9.64168 10.2178C9.69524 10.0887 9.77375 9.97145 9.8727 9.87276Z"
      fill="black"
    />
  </svg>
);
