import clsx from 'clsx';
import React from 'react';

interface Props {
  className?: string;
}

export const Spinner = ({ className }: Props) => {
  return (
    <>
      <div className={clsx('lg:flex hidden', className)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="174"
          height="174"
          viewBox="0 0 174 174"
          fill="none"
        >
          <g clipPath="url(#clip0_45_569)" fill="white">
            <path d="M87 37.1361C83.5985 37.1361 80.841 34.3789 80.841 30.9771V6.15899C80.841 2.75715 83.5985 0 87 0C90.4015 0 93.159 2.75715 93.159 6.15899V30.9774C93.159 34.3789 90.4015 37.1361 87 37.1361Z" />
            <path
              opacity="0.94"
              d="M108.169 41.292C107.394 41.292 106.606 41.1452 105.844 40.8336C102.695 39.549 101.184 35.9534 102.469 32.8041L111.848 9.82624C113.133 6.67691 116.728 5.16155 119.877 6.45194C123.027 7.73654 124.538 11.3321 123.252 14.4814L113.873 37.4593C112.899 39.8463 110.598 41.292 108.169 41.292Z"
            />
            <path
              opacity="0.88"
              d="M126.614 53.5448C125.038 53.5448 123.462 52.9432 122.259 51.7405C119.854 49.3358 119.854 45.4361 122.259 43.0314L139.808 25.4822C142.213 23.0764 146.112 23.0764 148.517 25.4822C150.923 27.8869 150.923 31.7866 148.518 34.1913L130.969 51.7405C129.767 52.9432 128.19 53.5448 126.614 53.5448Z"
            />

            <path
              opacity="0.82"
              d="M138.87 71.9891C136.442 71.9891 134.14 70.5444 133.166 68.1567C131.88 65.0074 133.391 61.4122 136.54 60.1272L159.518 50.7482C162.666 49.4599 166.262 50.9718 167.548 54.1225C168.833 57.2718 167.322 60.867 164.173 62.152L141.196 71.531C140.434 71.8423 139.645 71.9891 138.87 71.9891Z"
            />
            <path
              opacity="0.76"
              d="M167.841 93.159H143.023C139.621 93.159 136.864 90.4018 136.864 87C136.864 83.5982 139.621 80.841 143.023 80.841H167.841C171.242 80.841 174 83.5982 174 87C174 90.4018 171.242 93.159 167.841 93.159Z"
            />
            <path
              opacity="0.7"
              d="M161.844 123.71C161.069 123.71 160.28 123.563 159.518 123.252L136.541 113.873C133.391 112.588 131.881 108.993 133.166 105.843C134.452 102.696 138.046 101.184 141.196 102.469L164.174 111.848C167.323 113.133 168.834 116.728 167.548 119.878C166.574 122.264 164.272 123.71 161.844 123.71Z"
            />
            <path
              opacity="0.64"
              d="M144.163 150.322C142.586 150.322 141.011 149.721 139.808 148.518L122.259 130.969C119.854 128.564 119.854 124.664 122.259 122.259C124.664 119.855 128.564 119.855 130.969 122.259L148.518 139.809C150.923 142.213 150.923 146.113 148.517 148.518C147.315 149.721 145.739 150.322 144.163 150.322Z"
            />
            <path
              opacity="0.58"
              d="M117.552 168.006C115.124 168.006 112.822 166.56 111.848 164.173L102.469 141.196C101.184 138.046 102.695 134.451 105.844 133.166C108.991 131.879 112.588 133.391 113.873 136.54L123.252 159.518C124.538 162.667 123.026 166.262 119.877 167.547C119.116 167.859 118.327 168.006 117.552 168.006Z"
            />
            <path
              opacity="0.52"
              d="M87 174C83.5985 174 80.841 171.243 80.841 167.841V143.023C80.841 139.621 83.5985 136.864 87 136.864C90.4015 136.864 93.159 139.621 93.159 143.023V167.841C93.159 171.243 90.4015 174 87 174Z"
            />
            <path
              opacity="0.46"
              d="M56.4474 168.006C55.6722 168.006 54.8841 167.859 54.1222 167.547C50.9728 166.263 49.4619 162.667 50.7475 159.518L60.1262 136.54C61.4118 133.391 65.0063 131.879 68.1557 133.166C71.305 134.451 72.8159 138.046 71.5303 141.196L62.1516 164.173C61.1773 166.56 58.8759 168.006 56.4474 168.006Z"
            />
            <path
              opacity="0.4"
              d="M29.8369 150.322C28.2607 150.322 26.6848 149.721 25.4821 148.518C23.0771 146.113 23.0767 142.213 25.4818 139.809L43.0306 122.259C45.4357 119.854 49.3354 119.854 51.7405 122.259C54.1456 124.664 54.1459 128.564 51.7408 130.969L34.192 148.518C32.9893 149.721 31.4127 150.322 29.8369 150.322Z"
            />
            <path
              opacity="0.36"
              d="M12.1558 123.71C9.72731 123.71 7.42589 122.266 6.45155 119.878C5.16592 116.729 6.67687 113.133 9.82586 111.848L32.8034 102.469C35.9524 101.18 39.5476 102.693 40.8332 105.843C42.1188 108.993 40.6079 112.588 37.4589 113.873L14.4814 123.252C13.7195 123.563 12.931 123.71 12.1558 123.71Z"
            />

            <path
              opacity="0.3"
              d="M30.9767 93.159H6.15929C2.7578 93.159 0.000305176 90.4018 0.000305176 87C0.000305176 83.5982 2.7578 80.841 6.15929 80.841H30.9767C34.3782 80.841 37.1357 83.5982 37.1357 87C37.1357 90.4018 34.3782 93.159 30.9767 93.159Z"
            />
            <path
              opacity="0.24"
              d="M35.1293 71.9891C34.3541 71.9891 33.566 71.8423 32.8037 71.5307L9.82621 62.1517C6.67688 60.8671 5.16627 57.2715 6.4519 54.1222C7.73719 50.9728 11.3314 49.4622 14.4817 50.7479L37.4592 60.1269C40.6086 61.4115 42.1192 65.007 40.8336 68.1564C39.8592 70.5431 37.5575 71.9891 35.1293 71.9891Z"
            />
            <path
              opacity="0.18"
              d="M47.3858 53.5448C45.8092 53.5448 44.2337 52.9432 43.0307 51.7405L25.4818 34.1913C23.0767 31.7866 23.0767 27.8869 25.4821 25.4822C27.8869 23.0774 31.7866 23.0774 34.192 25.4822L51.7408 43.0314C54.1459 45.4361 54.1459 49.3358 51.7405 51.7405C50.5381 52.9432 48.9616 53.5448 47.3858 53.5448Z"
            />
          </g>
          <defs>
            <clipPath id="clip0_45_569">
              <rect width="174" height="174" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className={clsx('lg:hidden flex', className)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="124"
          height="124"
          viewBox="0 0 124 124"
          fill="none"
        >
          <g clipPath="url(#clip0_102_1748)">
            <path
              d="M62 26.4648C59.5759 26.4648 57.6108 24.4999 57.6108 22.0756V4.38916C57.6108 1.96487 59.5759 0 62 0C64.4241 0 66.3892 1.96487 66.3892 4.38916V22.0759C66.3892 24.4999 64.4241 26.4648 62 26.4648Z"
              fill="white"
            />
            <path
              opacity="0.52"
              d="M62 124C59.5759 124 57.6108 122.035 57.6108 119.611V101.924C57.6108 99.4998 59.5759 97.5349 62 97.5349C64.4241 97.5349 66.3892 99.4998 66.3892 101.924V119.611C66.3892 122.035 64.4241 124 62 124Z"
              fill="white"
            />
            <path
              opacity="0.58"
              d="M83.7729 119.728C82.0422 119.728 80.4021 118.698 79.7078 116.997L73.0241 100.622C72.1079 98.3777 73.1849 95.8156 75.4291 94.8999C77.6717 93.9827 80.235 95.0602 81.1512 97.3043L87.8349 113.679C88.7511 115.924 87.6741 118.486 85.4299 119.402C84.887 119.624 84.3253 119.728 83.7729 119.728Z"
              fill="white"
            />
            <path
              opacity="0.94"
              d="M77.0861 29.4265C76.5337 29.4265 75.972 29.3219 75.429 29.0998C73.1847 28.1843 72.1079 25.622 73.0241 23.3776L79.7078 7.00261C80.6237 4.75826 83.1856 3.67835 85.4299 4.59793C87.6743 5.5134 88.7511 8.07575 87.8349 10.3201L81.1512 26.6951C80.4569 28.3962 78.8168 29.4265 77.0861 29.4265Z"
              fill="white"
            />
            <path
              opacity="0.46"
              d="M40.2269 119.728C39.6744 119.728 39.1128 119.624 38.5698 119.402C36.3255 118.486 35.2487 115.924 36.1649 113.679L42.8485 97.3044C43.7647 95.06 46.3263 93.9825 48.5707 94.8999C50.8151 95.8154 51.8918 98.3777 50.9756 100.622L44.292 116.997C43.5976 118.698 41.9575 119.728 40.2269 119.728Z"
              fill="white"
            />
            <path
              opacity="0.36"
              d="M8.66281 88.1614C6.93213 88.1614 5.29204 87.1318 4.59769 85.4302C3.68149 83.1859 4.75826 80.6238 7.00237 79.708L23.3772 73.0242C25.6213 72.1053 28.1834 73.1835 29.0996 75.4286C30.0158 77.6729 28.939 80.235 26.6949 81.1508L10.3201 87.8346C9.77711 88.0567 9.21524 88.1614 8.66281 88.1614Z"
              fill="white"
            />
            <path
              opacity="0.82"
              d="M98.9651 51.3025C97.2344 51.3025 95.5943 50.273 94.9 48.5714C93.9838 46.327 95.0605 43.7649 97.3046 42.8492L113.679 36.1653C115.923 35.2472 118.486 36.3247 119.402 38.57C120.318 40.8144 119.241 43.3765 116.997 44.2922L100.622 50.9761C100.079 51.1979 99.5175 51.3025 98.9651 51.3025Z"
              fill="white"
            />
            <path
              opacity="0.24"
              d="M25.0347 51.3026C24.4823 51.3026 23.9206 51.1979 23.3774 50.9759L7.00261 44.292C4.75826 43.3765 3.68174 40.8142 4.59793 38.5698C5.51388 36.3255 8.07526 35.2489 10.3203 36.1651L26.6951 42.849C28.9395 43.7645 30.016 46.3268 29.0998 48.5712C28.4054 50.2721 26.7651 51.3026 25.0347 51.3026Z"
              fill="white"
            />
            <path
              opacity="0.7"
              d="M115.337 88.1613C114.785 88.1613 114.223 88.0567 113.68 87.8346L97.3049 81.1507C95.0605 80.2352 93.984 77.6729 94.9002 75.4285C95.8164 73.1859 98.3778 72.1084 100.623 73.0241L116.997 79.708C119.242 80.6235 120.318 83.1858 119.402 85.4302C118.708 87.1308 117.067 88.1613 115.337 88.1613Z"
              fill="white"
            />
            <path
              opacity="0.88"
              d="M90.2306 38.1584C89.1073 38.1584 87.9843 37.7297 87.1272 36.8726C85.4132 35.1589 85.413 32.3798 87.1269 30.6661L99.633 18.1597C101.347 16.4453 104.126 16.4453 105.84 18.1597C107.554 19.8735 107.554 22.6526 105.84 24.3663L93.3342 36.8726C92.4774 37.7297 91.3538 38.1584 90.2306 38.1584Z"
              fill="white"
            />
            <path
              opacity="0.4"
              d="M21.2631 107.126C20.1398 107.126 19.0168 106.697 18.1597 105.84C16.4457 104.127 16.4455 101.347 18.1595 99.6337L30.6655 87.1274C32.3795 85.4129 35.1586 85.4129 36.8726 87.1274C38.5865 88.8411 38.5868 91.6202 36.8728 93.3339L24.3667 105.84C23.5096 106.697 22.3861 107.126 21.2631 107.126Z"
              fill="white"
            />
            <path
              opacity="0.18"
              d="M33.7692 38.1583C32.6457 38.1583 31.5229 37.7296 30.6655 36.8725L18.1595 24.3662C16.4455 22.6525 16.4455 19.8734 18.1597 18.1597C19.8734 16.446 22.6525 16.446 24.3667 18.1597L36.8728 30.666C38.5868 32.3797 38.5868 35.1588 36.8726 36.8725C36.0157 37.7296 34.8922 38.1583 33.7692 38.1583Z"
              fill="white"
            />
            <path
              opacity="0.64"
              d="M102.737 107.126C101.613 107.126 100.49 106.697 99.633 105.84L87.1269 93.334C85.413 91.6203 85.4132 88.8412 87.1272 87.1275C88.8412 85.4137 91.6203 85.4137 93.3342 87.1275L105.84 99.6338C107.554 101.347 107.554 104.127 105.84 105.84C104.983 106.697 103.86 107.126 102.737 107.126Z"
              fill="white"
            />
            <path
              opacity="0.3"
              d="M22.0754 66.3892H4.38941C1.96535 66.3892 0.000242233 64.4243 0.000242233 62C0.000242233 59.5757 1.96535 57.6108 4.38941 57.6108H22.0754C24.4994 57.6108 26.4646 59.5757 26.4646 62C26.4646 64.4243 24.4994 66.3892 22.0754 66.3892Z"
              fill="white"
            />
            <path
              opacity="0.76"
              d="M119.611 66.3892H101.924C99.5001 66.3892 97.535 64.4243 97.535 62C97.535 59.5757 99.5001 57.6108 101.924 57.6108H119.611C122.035 57.6108 124 59.5757 124 62C124 64.4243 122.035 66.3892 119.611 66.3892Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_102_1748">
              <rect width="124" height="124" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </>
  );
};
