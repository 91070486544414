import axiosInstance from '.';

export const getOffers = async (limit = 9999) => {
  try {
    const response = await axiosInstance.get(`/offers?limit=${limit}`);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const acceptOffer = async ({ offer_id, user_token }) => {
  try {
    const response = await axiosInstance.post(`/offer/get`, {
      offer_id,
      user_token,
    });
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};
