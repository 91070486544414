import './styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';

import { App } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import routes from './AppRoutes';
import Layout from './components/Layout';
import { QueryClient, QueryClientProvider } from 'react-query';
import locale from 'antd/lib/locale/ru_RU';
import { ConfigProvider } from 'antd';

const MyApp = observer(() => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={locale}>
        <App>
          <BrowserRouter>
            <Layout>
              <Routes>
                {routes.map(({ path, element }) => (
                  <Route key={path} path={path} element={element} />
                ))}
              </Routes>
            </Layout>
          </BrowserRouter>
          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </App>
      </ConfigProvider>
    </QueryClientProvider>
  );
});

export default MyApp;
