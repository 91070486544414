interface Props extends React.SVGProps<SVGSVGElement> {}

export const MenuIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        fill="#F11F22"
        d="M1 6c0-.828.567-1.5 1.267-1.5h21.966c.7 0 1.267.672 1.267 1.5s-.567 1.5-1.267 1.5H2.267C1.567 7.5 1 6.828 1 6ZM1 19.5c0-.828.567-1.5 1.267-1.5h21.966c.7 0 1.267.672 1.267 1.5s-.567 1.5-1.267 1.5H2.267C1.567 21 1 20.328 1 19.5ZM1 12.5c0-.828.567-1.5 1.267-1.5H16.63c.7 0 1.268.672 1.268 1.5s-.568 1.5-1.268 1.5H2.267C1.567 14 1 13.328 1 12.5Z"
      />
      <path
        fill="#0A42D3"
        d="M20.431 12.5c0-.828.567-1.5 1.267-1.5h2.535c.7 0 1.267.672 1.267 1.5s-.567 1.5-1.267 1.5h-2.535c-.7 0-1.267-.672-1.267-1.5Z"
      />
    </g>
  </svg>
);
