interface Props extends React.SVGProps<SVGSVGElement> {}

export const ArrowDownIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      d="M8.00003 13C7.81403 13 7.62785 12.9267 7.48585 12.7803L0.213136 5.28029C-0.0710453 4.98723 -0.0710453 4.51267 0.213136 4.2198C0.497317 3.92692 0.957498 3.92673 1.2415 4.2198L8.00003 11.1895L14.7586 4.2198C15.0428 3.92673 15.5029 3.92673 15.7869 4.2198C16.0709 4.51286 16.0711 4.98742 15.7869 5.28029L8.51422 12.7803C8.37222 12.9267 8.18603 13 8.00003 13Z"
      fill="#0A42D3"
    />
  </svg>
);
