import React from 'react';
import News from '../../components/News';
import { useQuery } from 'react-query';
import { getNews } from '../../utils/httpServices/news';

const NewsPage = () => {
  const { data: news = [] } = useQuery(['news'], () => getNews(), {
    select: (data) => data.list,
  });

  return (
    <div className="flex justify-center w-full min-h-screen lg:pb-8 pb-4">
      <News data={news} />
    </div>
  );
};

export default NewsPage;
