interface Props extends React.SVGProps<SVGSVGElement> {}

export const RocketIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    {...props}
  >
    <path
      d="M71.2499 2.375C63.044 2.34238 54.9146 3.95196 47.3402 7.10896C39.7659 10.266 32.8997 14.9066 27.1461 20.7575C20.1111 27.7591 14.8227 36.3182 11.7086 45.7425C11.5797 46.1529 11.5646 46.5906 11.6649 47.0089C11.7653 47.4272 11.9775 47.8104 12.2786 48.1175L27.8586 63.6975C28.301 64.1363 28.8981 64.3836 29.5211 64.3862C29.7711 64.3834 30.0192 64.3434 30.2574 64.2675C39.6772 61.1437 48.2341 55.8566 55.2424 48.83C61.0937 43.0816 65.7348 36.2198 68.8919 28.6492C72.0491 21.0786 73.6583 12.9525 73.6249 4.75C73.6249 4.12011 73.3747 3.51602 72.9293 3.07062C72.4839 2.62522 71.8798 2.375 71.2499 2.375ZM30.1624 59.375L16.6249 45.8375C17.2661 44.0325 18.0261 42.2512 18.8336 40.5175L35.4586 57.1425C33.7249 57.9025 31.9674 58.6625 30.1624 59.375ZM51.8936 45.5762C48.2936 49.1572 44.2558 52.2693 39.8761 54.8387L21.2324 36.1237C27.3012 25.717 36.4211 17.4263 47.3574 12.3737C54.7255 15.3719 60.6024 21.172 63.6974 28.5C60.7967 34.8202 56.8005 40.5773 51.8936 45.505V45.5762ZM66.1436 22.7762C63.1005 17.4535 58.6889 13.0419 53.3661 9.99875C58.3668 8.28538 63.5923 7.31709 68.8749 7.125C68.7128 12.428 67.7684 17.6779 66.0724 22.705L66.1436 22.7762Z"
      fill="#3B3B3B"
    />
    <path
      d="M16.7674 59.2325C16.5466 59.0099 16.284 58.8332 15.9945 58.7126C15.7051 58.592 15.3947 58.53 15.0812 58.53C14.7676 58.53 14.4572 58.592 14.1678 58.7126C13.8784 58.8332 13.6157 59.0099 13.3949 59.2325L6.26993 66.3575C5.93642 66.6883 5.70849 67.1105 5.61495 67.5708C5.52141 68.0311 5.56647 68.5088 5.74441 68.9435C5.92236 69.3782 6.22522 69.7504 6.61468 70.013C7.00414 70.2756 7.46272 70.4168 7.93243 70.4187C8.24666 70.4174 8.5575 70.3538 8.84696 70.2315C9.13641 70.1092 9.39872 69.9307 9.61868 69.7062L16.7437 62.5812C17.1892 62.1394 17.4418 61.5392 17.4462 60.9118C17.4507 60.2843 17.2066 59.6806 16.7674 59.2325Z"
      fill="#3B3B3B"
    />
    <path
      d="M19.5936 65.4312L17.8124 67.1887C17.5898 67.4095 17.4131 67.6722 17.2925 67.9616C17.1719 68.251 17.1099 68.5615 17.1099 68.875C17.1099 69.1885 17.1719 69.4989 17.2925 69.7884C17.4131 70.0778 17.5898 70.3405 17.8124 70.5612C18.0332 70.7838 18.2958 70.9605 18.5853 71.0811C18.8747 71.2017 19.1851 71.2638 19.4986 71.2638C19.8122 71.2638 20.1226 71.2017 20.412 71.0811C20.7014 70.9605 20.9641 70.7838 21.1849 70.5612L22.9424 68.875C23.3315 68.4206 23.5348 67.8362 23.5117 67.2385C23.4886 66.6408 23.2408 66.0738 22.8178 65.6508C22.3949 65.2278 21.8279 64.98 21.2301 64.9569C20.6324 64.9338 20.048 65.1372 19.5936 65.5262V65.4312Z"
      fill="#3B3B3B"
    />
    <path
      d="M8.81113 58.1875L10.5686 56.4062C11.0127 55.9496 11.2572 55.3352 11.2483 54.6983C11.2394 54.0614 10.9778 53.4541 10.5211 53.01C10.0645 52.5659 9.45009 52.3215 8.81317 52.3304C8.17625 52.3393 7.56896 52.6008 7.12488 53.0575L5.34363 54.815C5.12103 55.0358 4.94434 55.2985 4.82377 55.5879C4.70319 55.8773 4.64111 56.1877 4.64111 56.5012C4.64111 56.8148 4.70319 57.1252 4.82377 57.4146C4.94434 57.704 5.12103 57.9667 5.34363 58.1875C5.56442 58.4101 5.8271 58.5868 6.11652 58.7074C6.40593 58.8279 6.71636 58.89 7.02988 58.89C7.34341 58.89 7.65384 58.8279 7.94325 58.7074C8.23267 58.5868 8.49535 58.4101 8.71613 58.1875H8.81113Z"
      fill="#3B3B3B"
    />
    <path
      d="M55.8125 56.2638L55.4087 55.195C50.5193 59.5215 45.0321 63.1209 39.1162 65.8825L41.4912 72.0813C41.6285 72.4537 41.8569 72.7858 42.1557 73.0471C42.4544 73.3085 42.8139 73.4909 43.2012 73.5775H43.7C44.0125 73.5793 44.3224 73.5194 44.6117 73.4012C44.9011 73.283 45.1643 73.1089 45.3862 72.8888L54.1262 64.125C55.1417 63.1209 55.8421 61.8422 56.1417 60.4458C56.4412 59.0495 56.3268 57.5961 55.8125 56.2638Z"
      fill="#F11F22"
    />
    <path
      d="M11.8751 21.8737L3.06385 30.685C2.78249 30.9663 2.57632 31.3138 2.46428 31.6956C2.35223 32.0774 2.3379 32.4812 2.4226 32.87C2.50924 33.2574 2.69158 33.6168 2.95296 33.9155C3.21434 34.2143 3.54642 34.4427 3.91885 34.58L10.1176 36.955C12.8737 31.0198 16.4646 25.5094 20.7813 20.5912L19.7363 20.1875C18.404 19.6732 16.9506 19.5588 15.5543 19.8583C14.1579 20.1578 12.8793 20.8583 11.8751 21.8737Z"
      fill="#F11F22"
    />
    <path
      d="M41.1826 45.505C47.0852 45.505 51.8701 40.72 51.8701 34.8175C51.8701 28.915 47.0852 24.13 41.1826 24.13C35.2801 24.13 30.4951 28.915 30.4951 34.8175C30.4951 40.72 35.2801 45.505 41.1826 45.505Z"
      fill="#F11F22"
    />
  </svg>
);
