import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {}

export const BankIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_74_12)">
      <path
        d="M12.8713 3.53937L7.18375 0.914375C7.12616 0.887722 7.06346 0.873917 7 0.873917C6.93654 0.873917 6.87384 0.887722 6.81625 0.914375L1.12875 3.53937C1.05281 3.57452 0.988541 3.63071 0.943565 3.70127C0.89859 3.77184 0.874794 3.85382 0.875001 3.9375V4.8125C0.875001 4.92853 0.921095 5.03981 1.00314 5.12186C1.08519 5.20391 1.19647 5.25 1.3125 5.25H12.6875C12.8035 5.25 12.9148 5.20391 12.9969 5.12186C13.0789 5.03981 13.125 4.92853 13.125 4.8125V3.9375C13.1252 3.85382 13.1014 3.77184 13.0564 3.70127C13.0115 3.63071 12.9472 3.57452 12.8713 3.53937Z"
        fill="white"
      />
      <path
        d="M12.6875 11.375H1.3125C1.07088 11.375 0.875 11.5709 0.875 11.8125V12.6875C0.875 12.9291 1.07088 13.125 1.3125 13.125H12.6875C12.9291 13.125 13.125 12.9291 13.125 12.6875V11.8125C13.125 11.5709 12.9291 11.375 12.6875 11.375Z"
        fill="white"
      />
      <path d="M6.125 6.125H7.875V10.5H6.125V6.125Z" fill="white" />
      <path d="M2.1875 6.125H3.9375V10.5H2.1875V6.125Z" fill="white" />
      <path d="M10.0625 6.125H11.8125V10.5H10.0625V6.125Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_74_12">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
