import React, { useState } from 'react';
// import { UserIcon } from '../Icons/User';
import { MenuIcon } from '../Icons/Menu';
import { CloseIcon } from '../Icons/Close';
import { menuItems } from './items';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const inNotMainPage = pathname !== '/';
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <div className="w-full lg:h-[96px] h-16 bg-white lg:px-[12.5%] px-5 py-[14px] flex items-center lg:justify-start justify-between">
        <div
          className="lg:w-[13%] w-1/4 aspect-[3.4] bg-contain bg-no-repeat bg-center"
          style={{ backgroundImage: `url(/images/header/logo.png)` }}
          role={inNotMainPage ? 'button' : undefined}
          tabIndex={inNotMainPage ? 0 : undefined}
          onClick={() => inNotMainPage && navigate('/')}
        />
        <div className="ml-[4%] lg:flex hidden items-center gap-[3%] font-medium text-[20px] w-full">
          {menuItems.map((el) => (
            <Link to={el.link} key={el.link + el.name}>
              <div>{el.name}</div>
            </Link>
          ))}
        </div>
        <div className="flex items-center gap-[10px] lg:ml-auto ml-0">
          {/* <UserIcon className="cursor-pointer" /> */}
          <div className="lg:hidden block">
            <MenuIcon
              role="button"
              aria-label="open menu"
              onClick={() => setShowMenu(true)}
              tabIndex={0}
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>
      {showMenu && (
        <div className="fixed top-0 right-0 bottom-0 left-0 bg-[#F6F7F8] z-50 p-5">
          <CloseIcon
            className="absolute right-5 top-6 cursor-pointer"
            role="button"
            tabIndex={0}
            onClick={() => setShowMenu(false)}
          />
          <h2 className="mt-[66px] mb-6 p-0 font-medium text-[22px]">Меню</h2>
          <div className="flex flex-col gap-[10px]">
            {menuItems.map((el) => (
              <div
                key={JSON.stringify(el)}
                className="flex gap-3 cursor-pointer"
                onClick={() => {
                  navigate(el.link);
                  setShowMenu(false);
                }}
              >
                <div
                  className="h-8 w-8 my-auto rounded-[5px] bg-white border border-[#C5C5C5]"
                  // style={{ background: `url(${el.src}) no-repeat center`, backgroundSize: '15px' }}
                  style={{
                    background: `url(${el.src}) no-repeat center`,
                    backgroundSize: 'contain',
                  }}
                />
                <div
                  className="w-full py-4 text-[18px]"
                  style={{ borderBottom: '1px solid #C5C5C5' }}
                >
                  {el.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
