import React from 'react';
import { Tag } from '../../components/Tag';
import { useWindowSize } from '@uidotdev/usehooks';
import { acceptOffer } from '../../utils/httpServices/offers';
import { toast } from 'react-toastify';

export default function Card({
  data,
  setIsShowModal,
  showCardsNum,
}: {
  data: any;
  setIsShowModal: (value: boolean) => void;
  showCardsNum: number;
}) {
  const size = useWindowSize();

  const handleAccept = () => {
    const user_token = localStorage.getItem('token');
    if (!user_token) {
      toast('Вы не авторизованы', { type: 'error' });
      return;
    }
    acceptOffer({ offer_id: data.id, user_token })
      .then((result) => {
        if (result.success) {
          setIsShowModal(true);
        } else {
          toast(result.message || 'Ошибка', { type: 'error' });
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <div
      // className="acard first:ml-4 last:mr-4 lg:min-w-auto min-w-[90vw] bg-[#fff] rounded-[20px] px-6 pt-4 pb-8 flex flex-col flex-auto"
      className="acard lg:min-w-auto min-w-[90vw] bg-[#fff] rounded-[20px] px-6 pt-4 pb-8 flex flex-col flex-auto shadow-lg"
      style={{
        minWidth: `${
          ((100 / showCardsNum) * (size?.width || window.innerWidth) * 0.75) / 100 -
          (showCardsNum > 2 ? 20 : 0)
        }px`,
      }}
    >
      <div className="min-h-[40px] max-h-[56px] flex items-center gap-6 mb-6">
        <Tag text={data.tag || '?'} />
      </div>
      <div className="bg-[#F6F7F8] p-4 rounded-[20px] mb-4 last:mb-0 lg:text-[20px] text-[18px]">
        <h3 className="font-medium mb-[5px]">{data.label}</h3>
        <p className="lg:text-base text-sm">{data.description}</p>
      </div>

      <button
        className="bg-[#0A42D3] rounded-[7px] text-base text-white font-medium w-full p-4 mt-auto transition-all"
        onClick={() => handleAccept()}
      >
        Подключить
      </button>
    </div>
  );
}
