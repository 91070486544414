/* eslint-disable indent */
// import queryString from 'query-string';

import axiosInstance from '.';

export const userLogin = async (data) => {
  try {
    const response = await axiosInstance.post('/user/login', data);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const checkToken = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) return { error: 'Token not found' };
    const response = await axiosInstance.post('/user/check', { token });
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const userRegister = async (data) => {
  try {
    const response = await axiosInstance.post('/user/register', data);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const userTakeCode = async (data) => {
  try {
    const response = await axiosInstance.post('/user/takeCode', data);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

// export const getOperations = async (filters) => {
//   try {
//     const stringifiedParams = filters
//       ? `?${queryString.stringify({
//           buyer: filters.buyer ? filters.buyer.id : undefined,
//           seller: filters.seller ? filters.seller.id : undefined,
//           limit: filters.limit,
//           page: filters.page,
//           sell_quarter: filters.sell_quarter || undefined,
//           sell_year: filters.sell_year || undefined,
//           buy_quarter: filters.buy_quarter || undefined,
//           buy_year: filters.buy_year || undefined,
//           kvo: filters.kvo || undefined,
//           buy_kvo: filters.buy_kvo || undefined,
//           from_1c: typeof filters.from_1c === 'number' ? filters.from_1c : undefined,
//           declaration: filters.declaration || undefined,
//           worker: typeof filters.worker === 'number' ? filters.worker : undefined,
//         })}`
//       : '';
//     const response = await axiosInstance.get(`/operations/all${stringifiedParams}`);
//     const users = response.data;
//     return users;
//   } catch (error) {
//     return { error: error.response.data };
//   }
// };

// export const deleteOperationById = async (id) => {
//   try {
//     const response = await axiosInstance.delete(`/operation/${id}`);
//     return response.data;
//   } catch (error) {
//     return { error: error.response.data };
//   }
// };

// export const getOperationsReplacements = async () => {
//   try {
//     const response = await axiosInstance.get('/operations/replacements');
//     return response.data;
//   } catch (error) {
//     return { error: error.response.data };
//   }
// };
