/* eslint-disable implicit-arrow-linebreak */
import { ReactNode } from 'react';

import { toast } from 'react-toastify';

export const copyToClipboard = (text: string, msg: ReactNode) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast(msg, { type: 'success', autoClose: 2000 });
    })
    .catch((error) => {
      console.error('Ошибка при копировании текста', error);
    });
};

export const FormatNumber = (number: any) => {
  if (Number.isNaN(number)) return 0;
  return new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2 })
    .format(number)
    .replace(',', '.');
};

export const formatNumber = (number: any) =>
  new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2 }).format(number).replace(',', '.');

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const formatPhone = (phone: string) => (phone ? phone.replace(/\D/g, '') : '');

export const toFormData = (obj: any) => {
  const formData = new FormData();
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'string') formData.append(key, value);
  });
};

// Функция для форматирования даты и времени ISO => ru-RU
export const FormatDate = (date: string | number | Date) =>
  new Date(date).toLocaleString('ru-RU', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

export const FormatFilledTableCell = (item: string, color = '#7A9AF8') => {
  const min = 0;
  const max = 100;
  const value: string =
    typeof item === 'string'
      ? Number.parseFloat(item.replace(/([^\d,.]+)/gi, '').replace(',', '.')).toFixed(2)
      : Number.parseFloat(item).toFixed(2);
  const filled = ((+value - min) / (max - min)) * 100;
  return `linear-gradient(to right, ${color} 0% ${filled}%, transparent ${filled}% ${
    100 - filled
  }%)`;
};

export const generateQuarters = () => {
  const currentYear = new Date().getFullYear();
  const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
  const yearBefore = currentYear - 4;
  return Array.from({ length: (currentYear - yearBefore) * 4 + currentQuarter }, (_, i) => {
    const year = Math.floor(i / 4) + yearBefore;
    const quarter = (i % 4) + 1;
    if (year === currentYear && quarter > currentQuarter) {
      return null;
    }
    return `${year} год, ${quarter} квартал`;
  })
    .filter((q) => q !== null)
    .reverse();
};

export const quarters = generateQuarters();

export const generateQuartersFiltered = (sfDate: string) => {
  const currentYear = new Date().getFullYear();
  const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
  const yearBefore = currentYear - 4;
  return Array.from({ length: (currentYear - yearBefore) * 4 + currentQuarter }, (_, i) => {
    const year = Math.floor(i / 4) + yearBefore;
    const quarter = (i % 4) + 1;
    if (year === currentYear && quarter > currentQuarter) {
      return null;
    }
    const quarterString = `${year} год, ${quarter} квартал`;
    let lastDay = 31;
    if (quarter === 2 || quarter === 3) lastDay = 30;
    const qs = `${year}-${(quarter - 1) * 3 + 3}-${lastDay} 23:59`;
    const quarterDate = new Date(qs);
    const fDate = new Date(sfDate.split('.').reverse().join('-'));
    if (quarterDate > fDate) {
      return quarterString;
    }
    return null;
  })
    .filter(Boolean)
    .reverse();
};
