import axiosInstance from '.';

export const getNews = async (limit = 9999) => {
  try {
    const response = await axiosInstance.get(`/news?limit=${limit}`);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};
