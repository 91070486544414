import React from 'react';

import LoginForm from './LoginForm';

import News from '../../components/News';
import Header from '../../components/Header/Header';
// import { mockData } from '../../components/News/mockData';
import { useQuery } from 'react-query';
import { getNews } from '../../utils/httpServices/news';

const Login = () => {
  const { data: news = [] } = useQuery(['news', 3], () => getNews(3), {
    select: (data) => data.list,
  });

  console.log('news3', news);

  return (
    <>
      <div className="flex flex-col items-center min-h-screen w-full bg-[#F6F7F8] lg:pb-8 pb-6">
        <Header />
        <div className="my-auto bg-white rounded-[20px] p-8 pb-[57px] font-medium lg:w-[466px] w-[90%] lg:mt-[105px] mt-[30px] lg:mb-[97px] mb-[30px]">
          <img src="/images/logo_.png" alt="" className="mx-auto" />
          <h2 className="text-[25px] my-6 text-center">Вход в систему</h2>
          <LoginForm />
        </div>
        <News text noHeader data={news} />
      </div>
    </>
  );
};

export default Login;
