import React, { useCallback, useEffect } from 'react';

import { userTakeCode } from '../../utils/httpServices/user';
import globalController from '../../mobx/GlobalController';
import { useNavigate } from 'react-router-dom';
import css from './Modal.module.css';

interface Props {
  code: string;
  setCode: any;
  setIsShowModal: (value: boolean) => void;
  token: string;
}

const Cell = ({ value }: { value: React.ReactNode }) => (
  <div className="w-[75px] h-[75px] border border-[#A3A3A3] rounded-[15px] flex items-center justify-center font-medium text-[40px] text-[#0A42D3]">
    {value || ''}
  </div>
);

const Modal = ({ code, setCode, setIsShowModal, token }: Props) => {
  const navigate = useNavigate();

  const takeCode = useCallback(async () => {
    const res = await userTakeCode({ code: +code, token });
    console.log(res);
    if (res?.success) {
      setTimeout(() => {
        navigate('/analyze', { replace: true });
      }, 2500);
    } else {
      globalController.handleError(res);
    }
  }, [code, navigate, token]);

  useEffect(() => {
    const handleKeyDown = (e: { key: string }) => {
      if (e.key === 'Escape') {
        setIsShowModal(false);
      }
      if (e.key === 'Backspace') {
        setCode((prev: string) => (prev.length ? prev.slice(0, -1) : prev));
      }
      if (Number.isFinite(+e.key)) {
        setCode((prev: string) => (prev.length < 4 ? prev + e.key : prev));
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (code.length === 4) {
      takeCode();
    }
  }, [code]);

  return (
    <div
      className="fixed top-0 left-0 bottom-0 right-0"
      style={{
        background: 'rgba(153, 162, 185, 0.30)',
        backdropFilter: 'blur(2.5px)',
      }}
    >
      <div className="rounded-[15px] bg-white min-w-[512px] min-h-[297px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="pt-[33px] pb-[37px]">
          <p className="font-medium text-[25px] text-center">
            Введите 4 последних цифры
            <br />
            номера телефона,
            <br />с которого поступит звонок
          </p>
          {/* <p className="text-center text-base"> с которого поступит звонок</p> */}
        </div>
        <div className="flex gap-[31px] w-fit mx-auto mb-8">
          {Array(4)
            .fill(0)
            .map((_, i) => (
              <Cell
                key={i}
                value={code[i] ? code[i] : i === code.length ? <div className={css.pulse} /> : ''}
              />
            ))}
        </div>
        <button
          type="submit"
          className="text-[#0A42D3] text-base font-medium cursor-pointer mx-auto w-fit mb-4"
        >
          Повторный запрос кода
        </button>
      </div>
    </div>
  );
};

export default Modal;
