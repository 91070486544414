import { useState } from 'react';
import MaskedInput from 'react-text-mask';
import { phoneMask } from './phoneMask';
import clsx from 'clsx';
import css from './InputField.module.css';
import { ArrowDownIcon } from '../../components/Icons/ArrowDown';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  options?: string[];
  onChoose?: (value: string) => void;
}

const inputClass =
  'mt-2 mb-3 px-[23px] py-3 border rounded-[7px] text-sm transition-all bg-[#F6F7F8] w-full';

export const InputField: React.FC<InputProps> = ({
  className,
  options,
  label,
  name,
  value,
  onChange,
  onChoose,
  type = 'text',
  placeholder,
  autoComplete,
  disabled = false,
  min,
  max,
}) => {
  const [focused, setFocused] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className={clsx('mb-3 last:mb-0', className)}>
      <label className={clsx('flex flex-col font-medium text-sm', focused && 'text-[#0A42D3]')}>
        {label}
        {type === 'tel' ? (
          <MaskedInput
            mask={phoneMask}
            placeholderChar={'\u2000'}
            placeholder={placeholder}
            name="tel"
            value={value}
            onChange={onChange}
            type="tel"
            className={inputClass}
            autoComplete={autoComplete}
            style={{
              backgroundColor: disabled ? '#0001' : '',
              color: disabled ? '#0008' : 'black',
              border: focused ? '1px solid #0A42D3' : '1px solid transparent',
              outline: 'none',
            }}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          />
        ) : type === 'dropdown' ? (
          <div className="relative" onClick={() => setIsDropdownOpen((p) => !p)}>
            <input
              name={name}
              value={value}
              onChange={() => {}}
              className={clsx(inputClass)}
              placeholder={placeholder}
              autoComplete={autoComplete}
              disabled={disabled}
              style={{
                backgroundColor: disabled ? '#0001' : '',
                color: disabled ? '#0008' : 'black',
                border: focused ? '1px solid #0A42D3' : '1px solid transparent',
                outline: 'none',
              }}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
            <ArrowDownIcon
              className="absolute top-[22px] right-4 cursor-pointer transition-all"
              style={{ transform: `rotate(${isDropdownOpen ? 180 : 0}deg)` }}
            />
            {isDropdownOpen && (
              <div
                className="absolute top-15 z-50 min-h-[20px] bg-[#F6F7F8] text-black rounded-[20px] overflow-hidden"
                style={{ width: 'calc(100% + 2px)' }}
              >
                {options?.map((el) => (
                  <div
                    key={el}
                    tabIndex={0}
                    className={clsx('px-3 py-2 cursor-pointer')}
                    onClick={() => {
                      if (onChoose) onChoose(el);
                      setIsDropdownOpen(false);
                    }}
                    style={el === value ? { background: 'rgba(10, 17, 211, 0.16)' } : {}}
                  >
                    {el}
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <input
            type={type}
            min={min}
            max={max}
            name={name}
            value={value}
            onChange={onChange}
            className={clsx(inputClass, type === 'date' && css.customDateInput)}
            placeholder={placeholder}
            autoComplete={autoComplete}
            disabled={disabled}
            style={{
              backgroundColor: disabled ? '#0001' : '',
              color: disabled ? '#0008' : 'black',
              border: focused ? '1px solid #0A42D3' : '1px solid transparent',
              outline: 'none',
            }}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          />
        )}
      </label>
    </div>
  );
};
